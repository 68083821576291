(function ($) {
    var $body = $('body');
    var $mobileNavToggle = $('body, .mobile-nav-button');
    var $contentContainer = $('.content-container');
    var $contactMenu = $('.contact-menu');

    initContactMenuClose();
    initContactMenuOpen();
    initWindowResizing();
    wrapYoutubeVideo();

    function initContactMenuOpen() {

        var timer = null;
        var delay = 350;
        var $topBar = $('#tabs-section .top-bar');
        var $scrollMore = $('#scroll-more');
        let $btnSidebar = $contactMenu.find('.contact-heading');
        
        $btnSidebar.on('click', function () {
            if ($topBar.length > 0 && $topBar.css('position') == "fixed") {
                $topBar.css('top', $topBar.position().top + window.scrollY);
                $topBar.css('width', '100%');
            }
            if ($scrollMore.length > 0) {
                $scrollMore.css('position', 'absolute');
                $scrollMore.css('left', 'calc(50% + 2.625rem)');
                $scrollMore.css('top', $scrollMore.position().top + window.scrollY);
            }
            $contentContainer.addClass("active");
            $body.addClass('no-scroll');
        });

/*         $contactMenu.on('mouseenter', function (e) {
            console.log(e.target);

            timer = setTimeout(function () {
                if ($topBar.length > 0 && $topBar.css('position') == "fixed") {
                    $topBar.css('top', $topBar.position().top + window.scrollY);
                    $topBar.css('width', '100%');
                }
                if ($scrollMore.length > 0) {
                    $scrollMore.css('position', 'absolute');
                    $scrollMore.css('left', 'calc(50% + 2.625rem)');
                    $scrollMore.css('top', $scrollMore.position().top + window.scrollY);
                }
                $contentContainer.addClass("active");
                $body.addClass('no-scroll');
            }, delay);
        }).on('blur', function (e) {
            console.log(e.target);

            clearTimeout(timer);
            $contentContainer.removeClass("active");
            $body.removeClass('no-scroll');
            setTimeout(function () {
                if ($topBar.length > 0 && $topBar.css('position') == "fixed") {
                    $topBar.css('top', '0');
                    $topBar.css('width', 'calc(100% - 5.25rem)');
                }
                if ($scrollMore.length > 0) {
                    $scrollMore.removeAttr('style');
                }
            }, 300);
        }); */

        $('.contact-flag-button').on('click', function () {
            $mobileNavToggle.removeClass('is-active');
            $contentContainer.addClass("active");
            $body.addClass('no-scroll');
        });
    }

    function initContactMenuClose() {
        $contactMenu.find('.close-sidebar').on('click touchstart', function (e) {
            e.stopPropagation();
            e.preventDefault();
            $contentContainer.removeClass("active");
            $body.removeClass('no-scroll');
        });

    }

    /* If mobile menu is active and window is resized, removes mobile nav classes
     * to prevent broken navigation. */
    function initWindowResizing() {
        var removeMobileContactOnResize = debounce(function () {
            if ($contactMenu.css('max-width') !== "320px") {
                $('.no-scroll').removeClass('no-scroll');
            }
        }, 400);
        window.addEventListener('resize', removeMobileContactOnResize);
    }

    function wrapYoutubeVideo(){
        if($('.layer.resource.video iframe').length > 0){
            $('.layer.resource.video iframe').wrap("<div class='yt-wrapper'></div>")
        }
    }
   
})(jQuery);